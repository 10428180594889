* {
  outline: none;
  box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
}


nav {
  width: 100%;
  background-color: $color-primary !important;


  div.wrap {
    display: table;
    min-height: 80px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    width: 90%;
    /*background-color:red; *********************************/
  }

  .contenedor {
    display: table-row;

    div {
      display: table-cell;
    }

    div.menu-wrap {
      text-align: right;

      ul {
        padding-left: 0;
      }
    }

    img {
      margin-top: 5px;
      margin-bottom: 5px;
    }


    li {
      margin-top: 15px;
      margin-left: 20px;
      list-style: none;
      display: inline-block;


      a {
        color: #fff;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 300;
        font-size: 16px;
      }

      a:hover {
        color: $color-secondary;
        text-decoration: none;
      }
    }

    li.divider {
      width: 1px;
      height: 16px;
      background-color: #fff;
    }

    button {
      display: none;
    }
  }
}



@media (max-width: $media-query-1-max-width) {
  nav {
    div.wrap {
      display: block;
    }

    .contenedor {
      display: block;

      div {
        display: block;
      }

      div.menu-wrap {
        text-align: left;
        display: none;
      }

      li {
        margin-top: 15px;
        margin-left: 0;
        display: block;

        a {
          display: block;
        }
      }

      li.divider {
        height: 1px;
        width: 16px;
        margin-top: 20px;
      }



      button {
        display: inline-block;
        width: 60px;
        height: 50px;
        background: none;
        border: none;
        -webkit-appearance: button;
        margin-top: -2px;
        outline: none;
        position: absolute;
        top: 16px;
        right: 20px;
      }

      button > span {
        background-color: rgba(255,255,255,1);
        left: 10px;
        cursor: pointer;
        border-radius: 2px;
        height: 5px;
        width: 35px;
        position: absolute;
        display: block;
        content: '';
      }

      button > span:before {
        transition: background-color 0.5s ease;
        background-color: rgba(255,255,255,1);
        left: 0px;
        cursor: pointer;
        border-radius: 2px;
        height: 5px;
        width: 35px;
        position: absolute;
        display: block;
        content: '';
        top: -10px;
        transition: all .5s ease;
      }

      button > span:after {
        transition: background-color 0.5s ease;
        background-color: rgba(255,255,255,1);
        left: 0px;
        cursor: pointer;
        border-radius: 2px;
        height: 5px;
        width: 35px;
        position: absolute;
        display: block;
        content: '';
        bottom: -10px;
        transition: all .5s ease;
      }
    }
  }
}



.buttonAfter > span {
  background-color: rgba(255,255,255,0) !important;
}

.buttonAfter > span:before {
  background-color: #fff !important;
  color: #fff !important;
  top: 0px !important;
  transform: rotate(45deg) !important;
}

.buttonAfter > span:after {
  background-color: #fff !important;
  color: #fff !important;
  top:0 !important;
  transform: rotate(-45deg) !important;
}






.titulo {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 32px;
  margin: 32px 0;
}

.cuerpo {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
}

.cuerpo-14 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
}


.popover-title {
  font-family: 'Source Sans Pro', sans-serif !important;
}

.popover-content {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-weight: 300;
}








.form-element {
  padding: 20px 0;

  .control-label {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    text-align: right;
    color: rgb(62, 72, 85);
    padding: 7px 10px 0 15px;
    padding-top: 7px;
  }

  .form-control {
    color: rgb(43, 47, 51);
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
  }
}

.form-element:before {
  display: table;
  content: ' ';
}

.form-element:after {
  clear: both;
  display: table;
  content: ' ';
}


.form-box {
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #dfe8f1;
  padding: 20px;
  margin: 25px 20px 20px 20px;

  .btn-primary {
    background-color: $color-primary !important;
    outline: none;
    border-color: $color-primary !important;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
  }
}


// FORM VALIDATION
.ovErrorMessage {
  display: none;
  color: #c50000;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight:300;
}

.ovErrorInput {
  border: 1px solid #c50000;
}

.alert, .alert > u {
  text-decoration: none !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
}

.link-box {
  text-align: center;
  margin-top: 40px;
}


//Ficha
#estcover {
  width: 100%;
  height:auto;
  margin: auto;
  text-align:center;
  position:relative;
  background-color: #000000;

  img {
    width:100%;
    opacity: 0.7;
  }

  h1 {
    position: absolute;
    margin: auto;
    padding: 0 25px;
    top: 0;
    left:0;
    right:0;
    bottom:0;
    color:#fff;
    height:120px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 60px;
    text-shadow: 0px 0px 12px rgba(0, 0, 0, 1);
  }
}

@media (max-width: $media-query-1-max-width) {
  #estcover {
    h1 {
      font-size: 36px;
      height: 90px;
    }
  }
}

@media (max-width: 600px) {
  #estcover {
    h1 {
      font-size: 24px;
      height: 60px;
    }
  }
}

.mTop40 {
  margin-top: 40px;
}

.mBottom40 {
  margin-bottom: 40px;
}

.rotulo-1 {
  font-size: 20px;
  font-weight: 300;
  color: #333333;

  i {
    color: #bf0005;
    width: 20px;
  }
}

#estmap {
  width:100%;
  height:300px;
}





/*
 * GALERÍA DE FOTOS
 */

.gallery-title
{
  font-size: 36px;
  color: #42B32F;
  text-align: center;
  font-weight: 500;
  margin-bottom: 70px;
}
.gallery-title:after {
  content: "";
  position: absolute;
  width: 7.5%;
  left: 46.5%;
  height: 45px;
  border-bottom: 1px solid #5e5e5e;
}
.filter-button
{
  font-size: 18px;
  border: 1px solid #42B32F;
  border-radius: 5px;
  text-align: center;
  color: #42B32F;
  margin-bottom: 30px;

}
.filter-button:hover
{
  font-size: 18px;
  border: 1px solid #42B32F;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  background-color: #42B32F;

}
.btn-default:active .filter-button:active
{
  background-color: #42B32F;
  color: white;
}

.port-image
{
  width: 100%;
}

.gallery_product
{
  margin-bottom: 30px;
}




/*
 * Dashboard Clientes
 */

.panel {
  margin-bottom: 20px;
  border: 1px solid #dfe8f1;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);

  h3 {
    color: rgb(62, 72, 85);
    font-size: 14px;
    padding: 0;
    margin: 15px;
    text-transform: uppercase;
    opacity: 0.7;
  }


  .dash-table {
    margin: 20px 20px 20px 20px;
    width: 94%;
    font-family: 'Source Sans Pro', sans-serif;


    td, th {
      padding: 8px;
      vertical-align: top;
    }

    td.left, th.left {
      text-align: left;
    }

    td.center, th.center {
      text-align: center;
    }

    td.right, th.right {
      text-align: right;
    }

    tr:nth-child(even) {
      background-color: #f5f5f5;
    }
  }
}





/*
 * HOME
 */
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

h1.rot1 {
  font-family: 'Source Sans Pro', sans-serif;
  margin: 0;
  line-height: 45px;
  margin-bottom: 12px;
  color: #333;
}

h2.rot2 {
  font-family: 'Source Sans Pro', sans-serif;
  margin: 0;
  line-height: 45px;
  margin-bottom: 12px;
  color: #333;
  font-size: 24px;
}
p.par1 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 18px;
  text-align: justify;
  line-height: 30px;
  color: #333;
}
.sep {
  width:100%;
  height: 60px;
}

a.rrss, a.rrss:focus, a.rrss:visited, a.rrss:active {
  color: #fff;
  margin-left: 12px;
}
a.rrss:hover {
  color: #eee;
}

.footerw {
  background-color:rgb(27,72,146);
  padding: 20px;

  a, a:focus, a:visited, a:active {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    margin-left: 12px;
    font-weight: lighter;
  }
  a:hover {
    color: #eee;
  }
}

.prefooter {
  background-color: rgba(172, 192, 220, 1);
  padding: 24px 0;
}

.titmini {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 18px;
  padding: 0;
  margin: 6px 0;
}

.subtitulo {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 14px;
  padding: 0;
  margin: 0;
}


// FORO
.foro-msg {
  border: 1px solid rgb(27,72,146);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;

  .header {
    background-color: rgb(27,72,146);
    padding: 12px;
    color: #ffffff;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    font-size: 16px;
  }

  .body {
    padding: 12px;

    .foro-title {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 300;
      font-size: 22px;
      color:rgb(204, 103, 85);
    }

    .foro-text {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 300;
      font-size: 16px;
      font-style: italic;
      padding: 6px 20px;
    }

    .foro-fecha {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 300;
      font-size: 14px;
    }
  }
}

.foro-clickable:hover {
  cursor: pointer;

  .body {
    background-color: #f5f5f5;
  }
}